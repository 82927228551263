import './App.css';

import {useEffect, useState, useRef} from 'react';

import { BrowserRouter } from 'react-router-dom'

import { JsonViewer } from '@textea/json-viewer'

import Go from './Go';


import hljs from 'highlight.js/lib/core';
import javascript from 'highlight.js/lib/languages/javascript';
import python from 'highlight.js/lib/languages/python';
import json from 'highlight.js/lib/languages/json';
import bash from 'highlight.js/lib/languages/bash';
import 'highlight.js/styles/github.css';
hljs.registerLanguage('javascript', javascript);
hljs.registerLanguage('python', python);
hljs.registerLanguage('bash', bash);
hljs.registerLanguage('json', json);


const snippets = [
{
  title: 'Chunking API Example', code: [
    {language:'javascript', text:
`const fetch = require('node-fetch');
const FormData = require('form-data');
const fs = require('fs');

const form = new FormData();
form.append(
  'files', 
  fs.createReadStream('./example.pdf')
);
form.append(
  'files', 
  fs.createReadStream('./example2.docx')
);

fetch('https://filechipper.com/upload', {
  method: 'POST',
  body: form,
}).then(response => response.json())
.then(data => console.log(data))
.catch(error => console.error('Error:', error));
`},
    {language: 'python', text:
`import requests
from requests_toolbelt.multipart.encoder import MultipartEncoder

api_url = "https://filechipper.com/upload"

multipart_data = MultipartEncoder(
    fields=[
        ('files', ('example.pdf', open('examples/example.pdf', 'rb'))),
        ('files', ('example2.docx', open('examples/example2.docx', 'rb')))
    ]
)

response = requests.post(api_url, data=multipart_data, headers={'Content-Type': multipart_data.content_type})
print(response.json())`
}, {language: 'bash', text:
`curl -X POST 'https://filechipper.com/upload' \\
-F 'files=@example.pdf' \\
-F 'files=@example2.pdf'`
},
]
},
{
  title: 'Response JSON', code: [{language: 'json', text:
`{
  "results": [
    [
      {
        "text": "Introduction to Hamilton's banking proposal, drawing on European precedents like the Bank of England.",
        "positionData": [
          {
            "page_idx": 0,
            "block_idx": 1,
            "bbox": [30, 50, 550, 100],
            "tag": "para"
          }
        ]
      },
      {
        "text": "Hamilton's argument for a national bank's benefits, citing Adam Smith and European banking laws.",
        "positionData": [
          {
            "page_idx": 0,
            "block_idx": 2,
            "bbox": [30, 110, 550, 160],
            "tag": "para"
          }
        ]
      }
    ]
  ]
}`}]
},
]

const responseSample = {
  "results": [
    [
      {
        "text": "Introduction to Hamilton's banking proposal, drawing on European precedents like the Bank of England.",
        "positionData": [
          {
            "page_idx": 0,
            "block_idx": 1,
            "bbox": [30, 50, 550, 100],
            "tag": "para"
          }
        ]
      },
      {
        "text": "Hamilton's argument for a national bank's benefits, citing Adam Smith and European banking laws.",
        "positionData": [
          {
            "page_idx": 0,
            "block_idx": 2,
            "bbox": [30, 110, 550, 160],
            "tag": "para"
          }
        ]
      }
    ]
  ]
}


const Code = ({snippet}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const codeRef = useRef(null); // Create a ref for the code element

  useEffect(() => {
    // hljs.highlightAll();
    if (codeRef?.current) {
      hljs.highlightElement(codeRef.current)
    }
  }, [selectedIndex]);

  // Function to handle language selection change
  const handleLanguageChange = (event) => {
    const selectedLanguage = event.target.value;
    const index = snippet.code.findIndex(code => code.language === selectedLanguage);
    if (index !== -1) {
      setSelectedIndex(index);
    }
  };

  return (
    <pre style={{width: '100%', boxShadow: '4px 4px 24px rgba(0, 0, 0, 0.25)', borderRadius: 8, padding: 16, boxSizing: 'border-box', margin: 0, backgroundColor: "#f8fbff"}}>
      <div style={{display: 'flex', justifyContent: 'space-between', paddingBottom: 12}}>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: 8}}>
          <div style={{width: 14, height: 14, background: '#ED6A5E', borderRadius: '100%'}}/>
          <div style={{width: 14, height: 14, background: '#F5BF4F', borderRadius: '100%'}}/>
          <div style={{width: 14, height: 14, background: '#62C655', borderRadius: '100%'}}/>
        </div>
        <h4 style={{color: 'black', textAlign: 'center', marginBottom: 0}}>{snippet.title}</h4>
      </div>
      <code ref={codeRef} key={snippet.code[selectedIndex].language} className={`language-${snippet.code[selectedIndex].language}`} 
        style={{borderRadius: 8, backgroundColor: "#f8fbff", padding: 0}}>
        {snippet.code[selectedIndex].text}
      </code>
      {snippet.code.length > 1 && <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: 12}}>
        {/* Dropdown for language selection */}
        <select onChange={handleLanguageChange} value={snippet.code[selectedIndex].language}>
          {snippet.code.map((code, index) => (
            <option key={index} value={code.language}>{code.language}</option>
          ))}
        </select>
      </div>}
    </pre>
  );
};


function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <header className="heroBanner">
          <div className="container fifty">
            <h1 className="hero__title">
              Filechipper API
            </h1>
            <p className="hero__subtitle">Chunk Your Files in Seconds</p>
             <Go to="https://docs.vault.pash.city/docs/chunking" external={true}>
              <div className="buttons">
                  <div
                    className="button button--secondary button--lg">
                    Dive into the Filechipper API Docs
                  </div>
              </div>
            </Go>
          </div>
          <div className="codeWrapper fifty" style={{minWidth: '50%'}}>
            <Code snippet={snippets[0]}/>
          </div>
        </header>
        <div className="responsePage">
          <div className="container fifty">
            <h1 className="hero__title" style={{width: '100%'}}>Deep positional data</h1>
            <p className="hero__subtitle" style={{width: '100%'}}>Chunks include detailed positional data like page index and bounding box, out of the box.</p>
            <div style={{width: '100%'}}><JsonViewer enableClipboard={false} rootName="response" value={responseSample} 
            displayDataTypes={false} displaySize={false} defaultInspectDepth={4}/></div>
          </div>
          <div className="codeWrapper fifty"><Code snippet={snippets[1]}/></div>
        </div>
        <div className="footer">
          <div>From the creators of <Go to="https://vault.pash.city" external={true}><span style={{color: '#8767f0', fontWeight: 700}}>Vault</span></Go></div>
          <div>{`© ${new Date().getFullYear()} Vault Intelligence, Inc.`}</div>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
